import React, { Component, useState } from 'react';
import './upload-box.css'
import { FaUpload } from "react-icons/fa"
import FillPrimaryButton from '../buttons/fill-primary';
import axios from 'axios';
import { Redirect } from "react-router-dom";



class UploadBox extends Component {
    state = {
        selectedFile: '',
        qrCode: '',
        hidden: 'hidden',
        hiddenSubmit: ''
    }


    onChangeFile = (e) => {
        this.setState({
            selectedFile: e.target.files[0]
        });
        // console.log(selectedFile);
    }

    submitForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("file", this.state.selectedFile)

        // console.log(this.state.selectedFile);
        console.log(formData.get("file"));

        axios.post("cb1/listening/upload", formData).then((response => {
            this.setState(
                {
                    qrCode: response.data,
                    hiddenSubmit: 'hidden',
                    hidden: ''
                }
            );
        }));
    }
    submitHandler = (e) => {
        this.setState(
            {
                hidden: 'hidden',
                hiddenSubmit: ''
            }
        );
        window.open(this.state.qrCode);
    }
    render() {
        const { selectedFile, hidden, hiddenSubmit, qrCode } = this.state;
        return (
            <div className={"htk-box upload-box"} style={this.props.style}>
                <div className="caption-line">
                    <h3>Dosya Yükle</h3>
                </div>
                <div className="upload-line">
                    <label htmlFor={"file-upload"} className="upload-frame">
                        <FaUpload />
                        <h3>Dosya Yükle</h3>
                        <p>Dosya formatı ses dosyası içeren MP3 olmalıdır.</p>
                    </label>
                    <form>

                        <FillPrimaryButton disabled={!selectedFile} text={"Gönder"} hidden={hiddenSubmit} onclick={this.submitForm} />
                        <FillPrimaryButton disabled={!selectedFile} hidden={hidden} text={"QR Codun hazır!"} onclick={this.submitHandler} />
                        <input
                            type="file"
                            name={"file"}
                            id={"file-upload"}
                            style={{ display: "none" }}
                            accept="audio/mp3,audio/*;capture=microphone"
                            onChange={this.onChangeFile}
                        />
                    </form>
                </div>
            </div>

        );
    }
}

export default UploadBox;
