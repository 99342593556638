import React, { Component } from 'react';
import UploadBox from './upload-box/upload-box';

const height = {
    height: 'calc((150vh - 160px) / 2)',
}


const center = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
}

export class Home extends Component {
    render() {
        return (
            <div style={center}>
                <UploadBox style={height}></UploadBox>
            </div>
        );
    }
}

