import React from 'react';

const FillPrimaryButton = (props) => {
    const style = {
        width: "max-content",
        padding: "6px 20px",
        border: "none",
        borderRadius: "50px",
        color: "white",
        backgroundColor: "blue",
        cursor: "pointer",
        fontSize: "14px",

    }
    return (
        <button style={style} display={props.display} hidden={props.hidden} onClick={props.onclick}>{props.text}</button>
    );
};

export default FillPrimaryButton;
